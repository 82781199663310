<template>
  <div>
    <window-header></window-header>
    <nav-form :nuevo="false" :editar="false" :eliminar="false"></nav-form>
    <section class="form">
      <article style="height:395px;width: 800px;">
        <field
          name="articulo_id.thumbnail"
          widget="m2o_img"
          style="width:57px; height:57px; top: 4px; left:6px;"
          inputStyle="display:none;"
        />
        <div class="filterbox">
          <field
            name="articulo_id.codigo"
            inline-label
            widget="char"
            label="Artículo"
            width="180px"
            style="top:6px; left:10px;"
            searchable
          />
          <field
            name="articulo_id.nombre"
            inline-label
            widget="char"
            label="Descripción"
            width="180px"
            style="top:6px; left:250px;"
            searchable
          />
          <field
            name="articulo_id.familia_id"
            inline-label
            widget="m2o"
            dbAdapter="familia"
            searchable
            label="Familia"
            width="180px"
            style="top:29px; left:10px;"
          />
          <field
            name="articulo_id.seccion_id"
            inline-label
            widget="m2o"
            dbAdapter="seccion"
            label="Sección"
            width="180px"
            style="top:29px; left:250px;"
            searchable
          />
          <font-awesome-layers
            class="remove-filter"
            style="position: absolute; right:20px;top:30px;z-index:9;"
          >
            <fa-icon icon="filter" />
            <fa-icon icon="times" style="right:0px;" transform="shrink-6" />
          </font-awesome-layers>
        </div>
        <div style="position:absolute; top: 70px; width: 100%;">
          <hot-table ref="hotTableComponent" :settings="htSettings" :height="320"></hot-table>
        </div>
      </article>
    </section>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    var self = this;
    return {
      title: "Inventario",
      dbAdapter: "inventario",
      primary: "id",
      fields: [{name:'articulo_id', fields:['codigo', 'nombre', {name:'thumbnail', fields:['imagen']}, {name:'familia_id', fields:['nombre']}, {name:'seccion_id', fields:['nombre']}]}]
    };
  }
};
</script>


<style>
.filterbox {
  position: absolute;
  left: 67px;
  top: 4px;
  height: 57px;
  width: 515px;
  border: 1px solid #ccc;
}
</style>